


























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from "vuex";
import VueFuse from 'vue-fuse';

import { ISearchBar } from '@/interfaces';

import { Tab } from "@/components/browse/interfaces/Tab.interface";
import ArtistTile from '@/components/ui/ArtistTile.vue';
import BackButton from '@/components/ui/BackButton.vue';
import Carousel from "@/components/ui/Carousel.vue";
import Grid from "@/components/browse/Grid.vue";
import LoopTable from '@/components/ui/LoopTable.vue';
import PackTile from '@/components/ui/PackTile.vue';
import SearchBar from '@/components/ui/SearchBar.vue';
import Tabs from '@/components/ui/Tabs.vue';
import Tile from '@/components/Tile.vue';
import VideoCarousel from "@/components/VideoCarousel.vue";
import VideoTile from '@/components/ui/VideoTile.vue';

@Component({
  components: {
    ArtistTile,
    BackButton,
    Carousel,
    Grid,
    LoopTable,
    PackTile,
    SearchBar,
    Tabs,
    VideoTile
  },
  computed: {
    ...mapState(["browse", "user"])
  }
})
export default class Browse extends Vue {
  activeTab = {
    name: "Packs",
    data: []
  };

  active = 0;

  browse: any;

  search = '';

  searchActive = false;

  filtered = false;
  filteredItem: any = {};

  loading = false;

  searchResults = [];

  $search!: Function;

  get gridItems() {
    return this.search ? this.results() : this.activeTab.data;
  }

  get tabs(): Tab[] {
    return [
      {
        name: "Packs",
        data: this.browse.packs
      },
      {
        name: "Videos",
        data: this.browse.videos
      },
      {
        name: "Artists",
        data: this.browse.artists
      }
    ];
  }

  get filteredItemLoops(): any {
    const loops: any = [];

    this.filteredItem.packs.forEach((pack: any) => {
      loops.push(...pack.loops);
    });

    return loops;
  }

  get headerAttrs(): any {
    return {
      class: 'dn-browse__header'
    }
  }

  get headerBgAttrs(): any {
    return {
      class: 'dn-browse__header-bg',
      style: this.filtered ? `background-image: url(${this.filteredItem.avatarUrl});` : ''
    }
  }

  results(): any {
    if (this.searchResults && this.search && !this.loading) {
      return this.searchResults;
    }

    return this.activeTab.data;
  }

  packs() {
    return this.browse.packs.filter((pack: any) => pack.published !== false);
  }

  mounted() {
    this.activeTab = this.tabs[0];
  }

  setTab(tab: Tab) {
    this.activeTab = tab;
  }

  handleSetTab(n: number) {
    this.active = n;
    this.setTab(this.tabs[n]);

    if (this.search) {
      this.handleSearch();
    }
  }

  handleSearch() {
    this.filtered = false;
    this.loading = true
    this.$search(this.search, this.activeTab.data, { keys: ['name', 'tags.name', 'tempo.name', 'category.name'] }).then((results: any) => {
      this.loading = false;
      this.searchResults = results;
    });
  }

  handleSearchFocus() {
    this.searchActive = !this.searchActive;
  }

  handleBack() {
    if (this.filtered) {
      // remove filtered obj, go back to default
      this.filteredItem = {};
      this.filtered = false;
      this.search = '';
      this.$refs.searchBar = this.$refs.searchBar as ISearchBar;
      
      if (this.$refs.searchBar.$data) {
        this.$refs.searchBar.$data.search = '';
      }
    } else {
      //go to previous page
      this.$router.go(-1);
    }
  }

  filterBy(type: string, item: any) {
    this.search = item.name;
    this.$refs.searchBar = this.$refs.searchBar as Vue;
    this.$refs.searchBar.$data.search = item.name;
    this.filtered = true;
    this.filteredItem = item;
  } 
}

